import React from "react";
import Navigation from "../src/components/Navigation/Navigation";
import Slider from "../src/components/HomeFour/Slider";
import About from "../src/components/HomeOne/About";
import Clix from "../src/components/HomeOne/Clix";
import HowItWorks from "../src/components/HomeOne/HowItWorks";
import Features from "../src/components/HomeOne/Features";
import Counter from "../src/components/HomeOne/Counter";
import Screenshots from "../src/components/Common/Screenshots";
import Testimonial from "../src/components/HomeOne/Testimonial";
import Faq from "../src/components/Common/Faq";
import AppDownload from "../src/components/HomeOne/AppDownload";
import Footer from "../src/components/Common/Footer";

const Index = () => {
  return (
    <>
      <Navigation />

      <Slider />

      <About />

      <HowItWorks />
      <Clix />

      <Features />

      <Counter />



      <Faq />
      <AppDownload />
      <Footer />
    </>
  )
}
  
export default Index;
