import React, { useState } from "react";

const AppDownload = () => {
  const [showIOSPopup, setShowIOSPopup] = useState(false);

  const handleIOSDownloadClick = (e) => {
    e.preventDefault();
    setShowIOSPopup(true);
  };

  const closePopup = (event) => {
    if (event.target.className === "popup") {
      setShowIOSPopup(false);
    }
  };

  return (
    <>
      <section id="app-download" className="app-download-section">
        <div className="container">
          <div
            className="section-title"
            data-aos="fade-in"
            data-aos-duration="2000"
            data-aos-delay="200"
          >
            <h2>NOW AVAILABLE</h2>
            <p>
              Discover the power of convenience at your fingertips! Experience seamless financial management, cross-border transactions, and community-driven banking. Download our app now from the Play Store and unlock a world of possibilities in your hands!
            </p>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div
                className="app-download-content"
                data-aos="fade-in"
                data-aos-duration="3000"
                data-aos-delay="300"
              >
                <a
                  href="#"
                  className="download-btn"
                  onClick={handleIOSDownloadClick}
                >
                  <i className="icofont-brand-apple"></i>
                  <span>
                    available on
                    <span className="large-text">Apple Store</span>
                  </span>
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.clic.wallet"
                  className="download-btn"
                  target="_blank"
                >
                  <i className="icofont-brand-android-robot"></i>
                  <span>
                    available on
                    <span className="large-text">Play Store</span>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {showIOSPopup && (
        <div className="popup" onClick={closePopup}>
        <div className="popup-inner">
          <h2>iOS Version Coming Soon!</h2>
          <p>The iOS version of our app is on its way. Stay tuned for updates.</p>
          <button onClick={() => setShowIOSPopup(false)}>Close</button>
        </div>
      </div>     
      )}
    </>
  );
};

export default AppDownload;
