import React, { useState } from "react";

const About = () => {
  const [showIOSPopup, setShowIOSPopup] = useState(false);

  const handleIOSDownloadClick = (e) => {
    e.preventDefault();
    setShowIOSPopup(true);
  };

  const closePopup = (event) => {
    if (event.target.className === "popup") {
      setShowIOSPopup(false);
    }
  };
  return (
    <>
      <section id="about" className="app-about-section">
        <div className="container">
          <div
            className="section-title"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="100"
          >
            <h2>About Clic</h2>
            <p>
            We have developed a comprehensive financial solution, covering microfinance, groups/chamaz, remittances, and digital asset transactions etc. Powered by three core platforms—the Clic Wallet, Clix Wallet, Social Banking System, and Marketplace—it ensures seamless financial services for communities.
</p>
          </div>
         
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div
                className="app-about-text"
                data-aos="fade-in"
                data-aos-duration="2000"
                data-aos-delay="200"
              >
                <h3>The Social Banking model</h3>
                <p>
                The reason why traditional banking has failed in the African financial space can be found in the flawed centralised capitalistic model that these banks have applied. The African financial space is fundamentally socialistic and has always been decentralised, based on communities and groups where savings and loans were handled by the group or community.
                </p>
                <p>
                Today, this model makes up more than 80% of the consumer financial activities and explains why decades and millions of dollars later the “bank the unbanked” efforts by mainly western NGO’s have failed. In reality the majority of the African population were not “unbanked”, they were simply “socially banked” or “community banked.
                </p>

                <ul>
                  <li>
                    <i className="icofont-verification-check"></i> Responsive
                    design
                  </li>
                  <li>
                    <i className="icofont-verification-check"></i> Easy to
                    customize
                  </li>
                  <li>
                    <i className="icofont-verification-check"></i> Modern design
                  </li>
                </ul>
 
              </div>
            </div>

            <div className="col-lg-5">
              <div
                className="text-center"
                data-aos="fade-in"
                data-aos-duration="3000"
                data-aos-delay="300"
              >
                <img src="/images/sb1.png" alt="App About Image" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {showIOSPopup && (
        <div className="popup" onClick={closePopup}>
        <div className="popup-inner">
          <h2>iOS Version Coming Soon!</h2>
          <p>The iOS version of our app is on its way. Stay tuned for updates.</p>
          <button onClick={() => setShowIOSPopup(false)}>Close</button>
        </div>
      </div>     
      )}
    </>
  );
};

export default About;
