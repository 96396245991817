import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const alertContent = () => {
  MySwal.fire({
    title: "Congratulations!",
    text: "Your message was successfully sent. We'll get back to you soon.",
    icon: "success",
    timer: 2000,
    timerProgressBar: true,
    showConfirmButton: false,
  });
};

// Form initial state
const INITIAL_STATE = {
  name: "",
  email: "",
  amountUSD: "",
};

const Clix = () => {
  const [formData, setFormData] = useState(INITIAL_STATE);
  const [xlmPriceUSD, setXlmPriceUSD] = useState(null); // XLM to USD price
  const [clixPriceUSD, setClixPriceUSD] = useState(null); // CLIX to USD price
  const [clixAmount, setClixAmount] = useState("");

  // Fetch XLM-USD price from CoinGecko
  useEffect(() => {
    const fetchXlmPrice = async () => {
      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/simple/price?ids=stellar&vs_currencies=usd"
        );
        const xlmPrice = response.data.stellar.usd;
        setXlmPriceUSD(xlmPrice);
      } catch (error) {
        console.error("Error fetching XLM price:", error);
      }
    };

    fetchXlmPrice();
  }, []);

  // Fetch CLIX-XLM price from Stellar Decentralized Exchange
  useEffect(() => {
    const fetchClixPrice = async () => {
      try {
        const response = await axios.get(
          "https://horizon.stellar.org/order_book?buying_asset_type=credit_alphanum4&buying_asset_code=CLIX&buying_asset_issuer=GBCJSKXTZX5CYKJGBGQPYEATLSGR4EPRUOL7EKIDCDOZ4UC67BBQRCSO&selling_asset_type=native"
        );
        const bestAskPrice = 1/response.data.bids[0]?.price || null;
        if (bestAskPrice && xlmPriceUSD) {
          const clixUsdPrice = (bestAskPrice * xlmPriceUSD).toFixed(5); // Convert CLIX-XLM to CLIX-USD
          setClixPriceUSD(clixUsdPrice);
        }
      } catch (error) {
        console.error("Error fetching CLIX price:", error);
      }
    };

    if (xlmPriceUSD) {
      fetchClixPrice();
    }
  }, [xlmPriceUSD]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (name === "amountUSD" && clixPriceUSD) {
      const calculatedClix = value ? (value / clixPriceUSD).toFixed(2) : "";
      setClixAmount(calculatedClix);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Proceed to StellarTerm",
      text: `You will purchase ${clixAmount} CLIX tokens for ${formData.amountUSD} USD. Please complete your purchase on StellarTerm.`,
      icon: "info",
      confirmButtonText: "Go to StellarTerm",
    }).then((result) => {
      if (result.isConfirmed) {
        const url = `https://stellarterm.com/swap/USDC-www.centre.io/CLIX-clic.world`;
        window.open(url, "_blank");
      }
    });
  };

 
const blockchainData = [
  {
    network: "Stellar",
    details: [
      { label: "Asset Code", value: "CLIX" },
      { label: "Issuing Address", value: "GBCJSKXTZX5CYKJGBGQPYEATLSGR4EPRUOL7EKIDCDOZ4UC67BBQRCSO" },
    ],
  },
  {
    network: "Polygon",
    details: [

    { label: "Asset Code", value: "CLIX" },
      { label: "Contract address", value: "" },
       ],
  },
];

 
const BlockchainInfo = () => {
  return (
    <div  className="blockchain-info-section pt-50 pb-50">
      <div className="container">
        <div className="row">
          {blockchainData.map((network, index) => (
            <div className="col-md-6 col-lg-6" key={index}>
              <div
                className="card"
                style={{
                  border: "1px solid #ddd",
                  borderRadius: "10px",
                  padding: "10px",
                  marginBottom: "10px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                <h3 style={{ marginBottom: "5px", textAlign: "left" }}>
                  {network.network}
                </h3>
                {network.details.map((detail, idx) => (
                  <p key={idx} style={{ marginBottom: "5px", textAlign: "left" }}>
                    <strong>{detail.label}:</strong> {detail.value}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

  return (
    <section id="clix" style={{background:'#F1F1F1'}} className="clix-section gray-bg">
      <div className="container">
        <div className="row">
          {/* Left Section */}
          <div className="col-lg-6">
            <div
              className="section-title"
              data-aos="fade-in"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              <h2><img width={40} src="../images/clixtoken.png"/> CLIX </h2>
              <h6>
                CLIX is the powerhouse of the clic.world ecosystem.</h6>
               
                <p>The CLIX token is a utility token provided by Clic.World to power the
                social finance ecosystem. It enables banking to be returned to
                the community level. With CLIX, users can form groups, manage
                their finances collaboratively, and eliminate the need for
                third-party organizations. Empower your community with a tool
                designed for transparency and financial inclusivity.
              </p>

            </div>
          </div>

          {/* Right Section */}
          <div className="col-lg-6">
            <div
              className="contact-form"
              data-aos="fade-in"
              data-aos-duration="2000"
              data-aos-delay="200"
            >
              <h3>Buy CLIX</h3>
              <p>Fill in the form below to calculate and proceed with your purchase:</p>
              <form onSubmit={handleSubmit}>
               

                <div className="form-group">
                  <label htmlFor="amountUSD">Amount in USD</label>
                  <input
                    type="number"
                    name="amountUSD"
                    placeholder="Enter amount in USD"
                    className="form-control"
                    value={formData.amountUSD}
                    onChange={handleChange}
                    required
                  />
                </div>

                   <div className="calculation">
                    <p>
                      Current CLIX Price: <strong>{clixPriceUSD} USD</strong>
                    </p>
                    <p>
                      You will receive approximately{" "}
                      <strong>{clixAmount || "0"} CLIX</strong> for{" "}
                      <strong>${formData.amountUSD || "0"}</strong>.
                    </p>
                  </div>
                

                <div className="text-center">
                  <button
                    type="submit"
                    className="default-button"
                    disabled={!clixAmount}
                  >
                    Buy CLIX
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="row">
          <h3>CLIX addresses</h3>

        <BlockchainInfo/>
        </div>

      </div>
    </section>
  );
};

export default Clix;
