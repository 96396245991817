import React, { useState, useEffect } from 'react';

const sliderData = [
  {
    heading: "Clic App",
    text: "Revolutionize how you manage your community's finances with Clic. From joining Saccos to managing savings groups and facilitating seamless loan processes, Clic empowers you with cutting-edge Social Banking tools. Build stronger communities by fostering financial growth and shared prosperity.",
    image: "/images/clic.png",
    link: "https://play.google.com/store/apps/details?id=com.clic.wallet"
  },
  {
    heading: "Clix Wallet",
    text: "Experience the next generation of digital finance with Clix Wallet. Securely buy, sell, and manage your digital assets through a blockchain-powered wallet, all backed by the innovative CLIX token. Whether you're a trader or a holder, Clix Wallet makes digital asset management seamless and efficient.",
    image: "/images/clix.png",
    link: "https://play.google.com/store/apps/details?id=com.clic.clicapp"
  }
];


const HomeSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showIOSPopup, setShowIOSPopup] = useState(false);

  const handleIOSDownloadClick = (e) => {
    e.preventDefault();
    setShowIOSPopup(true);
  };

  const closePopup = (event) => {
    if (event.target.className === "popup") {
      setShowIOSPopup(false);
    }
  };

  const next = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === sliderData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? sliderData.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(next, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div id="home" className="homepage-slides-wrapper gradient-bg wave-bg-two">
      {sliderData.map((item, index) => (
        <div
          key={index}
          className="single-slider-item"
          style={{ display: currentIndex === index ? 'block' : 'none' }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 col-lg-7">
                <div className="slider-text" style={{ textAlign: "left" }}>
                  <h1>{item.heading}</h1>
                  <p>{item.text}</p>
                  <div className="slide-button" style={{ textAlign: "left" }}>
                    <a
                      href={item.link}
                      target="_blank"
                      className="slide-btn-white mr-10"
                    >
                      {/* <i className="icofont-brand-android-robot"></i> */}
                      <img src="/images/play2.png" alt="Play Store" style={{ width: '22px', height: '22px', marginRight: '5px' }} />
                      <span>
                        <span className="large-text">Play Store</span>
                      </span>
                    </a>
                    <a
                      href="#"
                      className="slide-btn-bordered"
                      onClick={handleIOSDownloadClick}
                    >
                      <i className="icofont-brand-apple"></i>
                      <span>
                        <span className="large-text">Apple Store</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-5">
                <div className="welcome-phone">
                  <img src={item.image} style={{marginTop:50}} alt="App Mockup" />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <button className="button-download-app" onClick={prev}>&lt;</button>
      <button className="button-download-app" onClick={next}>&gt;</button>
      {showIOSPopup && (
        <div className="popup" onClick={closePopup}>
        <div className="popup-inner">
          <h2>iOS Version Coming Soon!</h2>
          <p>The iOS version of our app is on its way. Stay tuned for updates.</p>
          <button onClick={() => setShowIOSPopup(false)}>Close</button>
        </div>
      </div>     
      )}
    </div>
  );
};

export default HomeSlider;
