import React from "react";

const featuresData = [
  {
    icon: "icofont-wallet",
    type: "app",
    title: "Clic Wallet",
    text: "Revolutionize how you manage your community's finances with Clic Wallet. From joining Saccos to managing savings groups and facilitating seamless loan processes, Clic Wallet empowers you with cutting-edge Social Banking tools. Build stronger communities by fostering financial growth and shared prosperity.",
    duration: "1000",
    delay: "100",
    link: "https://play.google.com/store/apps/details?id=com.clic.wallet",
    image: "/images/clic.png",
  },
  {
    icon: "icofont-group",
    type: "sb",
    title: "Social Banking",
    text: "Engage in community-driven financial activities with the Social Banking System. Join Saccos, create and manage savings groups, and facilitate group loans for mutual benefit. Empower communities with tools that foster collaboration, growth, and financial inclusivity.",
    duration: "3000",
    delay: "300",
    link: "#",
    image: "/images/social-banking.png",
  },
  {
    icon: "icofont-wallet",
    type: "app",
    title: "Clix Wallet",
    text: "Experience the next generation of digital finance with Clix Wallet. Securely buy, sell, and manage your digital assets through a blockchain-powered wallet, all backed by the innovative CLIX token. Whether you're a trader or a holder, Clix Wallet makes digital asset management seamless and efficient.",
    duration: "2000",
    delay: "200",
    link: "https://play.google.com/store/apps/details?id=com.clic.clicapp",
    image: "/images/clix.png",
  },
  {
    icon: "icofont-shopping-cart",
    type: "sb",
    title: "Marketplace",
    text: "Explore the clic marketplace, a dynamic online marketplace. Buy and sell goods, access merchant-specific tools, and leverage business-enhancing features to grow your presence in the marketplace ecosystem.",
    duration: "4000",
    delay: "400",
    link: "https://kibatu.ug",
    image: "/images/marketplace.png",
  },
];

const Features = () => {
  return (
    <>
      <div id="features" className="awsome-features pt-100 pb-70">
        <div className="container">
          <div
            className="section-title"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="100"
          >
            <h2>Our Products</h2>
            <p>
              Discover the powerful tools and platforms that make up the Clic ecosystem. From financial management to digital asset solutions, community-driven banking, and dynamic marketplaces, explore how we empower your financial journey.
            </p>
          </div>

          <div className="row">
            {featuresData &&
              featuresData.map((value, i) => (
                <div
                  className="col-md-3 d-flex"
                  key={i}
                  style={{ marginBottom: "20px" }}
                >
                  <a
                    href={value.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="card feature-card text-center w-100"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      minHeight: "300px", // Ensures uniform height
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: "1px solid #ddd",
                      borderRadius: "10px",
                      padding: "20px",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                      transition: "transform 0.3s ease, box-shadow 0.3s ease",
                    }}
                    onMouseOver={(e) =>
                    (e.currentTarget.style.boxShadow =
                      "0px 8px 15px rgba(0, 0, 0, 0.2)")
                    }
                    onMouseOut={(e) =>
                    (e.currentTarget.style.boxShadow =
                      "0px 4px 6px rgba(0, 0, 0, 0.1)")
                    }
                  >
                    <div className="feature-icon">
                      <i
                        className={value.icon}
                        style={{
                          fontSize: "40px",
                          color: "#007bff",
                          marginBottom: "15px",
                        }}
                      ></i>
                    </div>
                    <h4 style={{ marginBottom: "10px" }}>{value.title}</h4>
                    <p style={{ fontSize: "14px", color: "#555" }}>
                      {value.text}
                    </p>
                    {value.type == "app" ?
                      <a
                        href={value.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="default-button"
                      >
                        Download
                      </a> :
                      <a
                        href={value.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="default-button"
                      >
                        Visit
                      </a>
                    }
                  </a>


                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
